import "./Numpad.css"
import React, { useEffect, useState } from "react"
import { Layout, Menu, Breadcrumb, Modal, Form, Input, Button, Checkbox, AutoComplete, Typography, notification, Divider } from "antd"
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import SearchBar from "../../common/SearchBar"
import { Customer, StyleSheet, Order, InvoiceHead, InvoiceItem, CartItem, PaymentTotal } from "../../../models"
import NumPad from "react-numpad"
import useViewport from "../../../hooks/useViewport"
import PaymentTotalSection from "./PaymentTotalSection"
import { setPaymentTotal, setCurrentInvoice, setShowReceiptModal, setPaymentStep, setPaymentTotal1, setPaymentTotal2, setAllPaymentTotal } from "../../../redux/Payment/slice"
import { calculatePaymentRounding, cartItemsToProductCodeOnlyItems, randomInteger } from "../../../utils/Global"
import { postInvoice } from "../../../api/invoice"
import ProgressModal from "../../common/ProgressModal"
import { clearCart } from "../../../redux/Checkout/slice"
import { useNavigate } from "react-router-dom"
import { addInvoice, serveCustomer } from "../../../redux/Customer/slice"
import { EmptyPaymentTotal, ExampleInvoiceInDB } from "../../../constants/data"
import ReceiptModal from "./ReceiptModal"
import { cartItemsToInvoiceItems } from "../../../utils/Global"
import ActionConfirmModal from "../../common/ActionConfirmModal"
import { reloadCustomerScreen } from "../../../utils/customerscreen-functions"
import AlertModal from "../../common/AlertModal"
import { BackendPaymentMethod } from "../../../constants/Enums"

interface CashModalProps {
  isVisible: boolean
  setVisibility: (value: boolean) => void
  handleSplitPayment: () => void
  postInvoiceToBackend: (payBy: string, payAmount: number) => void
  setIsCheckoutPaymentModalVisible?: (value: boolean) => void
}

const myTheme = {
  // fontFamily: "Arial",
  textAlign: "center",
  header: {
    primaryColor: "#263238",
    secondaryColor: "#f9f9f9",
    highlightColor: "#FFC107",
    backgroundColor: "#607D8B"
  },
  body: {
    primaryColor: "#263238",
    secondaryColor: "#32a5f2",
    highlightColor: "#FFC107",
    backgroundColor: "#f9f9f9"
  },
  panel: {
    backgroundColor: "#CFD8DC"
  }
};


const CashModal = ({ isVisible, setVisibility, handleSplitPayment, postInvoiceToBackend, setIsCheckoutPaymentModalVisible }: CashModalProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width, height } = useViewport()
  const Customer = useSelector((state: RootStateOrAny) => state.Customer)
  const { servingCustomer, customers } = Customer
  const Checkout = useSelector((state: RootStateOrAny) => state.Checkout)
  const { total, itemsInCart } = Checkout
  const Payment = useSelector((state: RootStateOrAny) => state.Payment)
  const { paymentTotal, paymentTotal1, paymentTotal2, paymentType, paymentStep, paymentMethod1, paymentMethod2 } = Payment

  const [amountPaid, setAmountPaid] = useState(0)
  const [isInProgress, setIsInProgress] = useState<boolean>(false)
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false)


  const handleAmountPaidChange = (mode: "append" | "input" | "reset", value: number) => {
    let currentPaymentTotal = EmptyPaymentTotal
    if (paymentType === "oneOff") {
      currentPaymentTotal = paymentTotal
    } else if (paymentStep === "split1") {
      currentPaymentTotal = paymentTotal1
    } else if (paymentStep === "split2") {
      currentPaymentTotal = paymentTotal2
    }
    if (mode === "reset") {
      setAmountPaid(0)
      const newPaymentTotal = {
        ...currentPaymentTotal,
        depositAmount: 0,
        changeAmount: 0
      }
      dispatch(setAllPaymentTotal({ paymentTotal: newPaymentTotal }))
    } else {
      const amountPaidValue = mode === "append" ? amountPaid + value : value
      const changeValue = amountPaidValue - currentPaymentTotal.receivableAmount
      const roundingChangeValue = Number(calculatePaymentRounding(changeValue).payable)
      const newPaymentTotal = {
        ...currentPaymentTotal,
        depositAmount: amountPaidValue,
        changeAmount: roundingChangeValue > 0 ? roundingChangeValue : 0
      }
      dispatch(setAllPaymentTotal({ paymentTotal: newPaymentTotal }))
      setAmountPaid(amountPaidValue)
    }
  }

  const handleProceedPayment = () => {
    let currentPaymentTotal = EmptyPaymentTotal
    if (paymentType === "oneOff") {
      currentPaymentTotal = paymentTotal
    } else if (paymentStep === "split1") {
      currentPaymentTotal = paymentTotal1
    } else if (paymentStep === "split2") {
      currentPaymentTotal = paymentTotal2
    }
    if (Number(amountPaid) < Number(currentPaymentTotal.receivableAmount.toFixed(2))) {
      setErrorMsg(`Paid Amount ${amountPaid} is Less than Receivable Amount ${currentPaymentTotal.receivableAmount}!`)
      setIsAlertModalVisible(true)
    } else {
      console.log(`Paid Amount ${amountPaid} is Match Receivable Amount ${currentPaymentTotal.receivableAmount}!`)
      if (paymentType === "oneOff" || paymentStep === "split2") {
        setVisibility(false)
        postInvoiceToBackend(BackendPaymentMethod.Cash, Number(amountPaid))
      } else if (paymentStep === "split1") {
        setVisibility(false)
        handleSplitPayment()
      }
    }
  }

  const getNumpadElement = () => {
    // document.getElementsByClassName("sc-htoDjs")[0].getElementsByClassName.display
    // console.log("result: ", result)
  }


  return (
    <Modal
      className="modalPage"
      title={<div className="modalTitle">Cash Payment<div style={{ width: "40px", marginTop: "-10px" }}><Divider className="modalDivider" /></div></div>}
      transitionName="none"
      maskTransitionName="none"
      visible={isVisible}
      footer={null}
      // onCancel={(e) => {
      //   setVisibility(false)
      // }}
      width={width * 0.8}
      zIndex={500}
      closable={false}
      style={{ height: height * 0.8 }}
    >
      <div style={{ display: "flex", flexDirection: "row", marginTop: "-40px" }}>
        <div style={{ display: "flex", flexDirection: "column", width: "514px", backgroundColor: "#F2F2F2", borderRadius: "10px" }}>
          <PaymentTotalSection />
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <div className="normalLabel">Amount Paid: </div>
            <div style={{display: "flex", alignItems: "center"}}>
            <div style={{ fontSize: 22, fontWeight: "bold", zIndex: "1000"}}>$</div>
            <Input style={{ width: 180, fontSize: 22, fontWeight: "bold", height: 40, textAlign: "right", marginLeft: "-22px" }} value={amountPaid} />
            </div>
            <div style={{ marginTop: 10, fontSize: 18, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <div className="normalLabel">Quick Select: </div>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", }}>
                <button
                  style={styles.button}
                  onClick={() => {
                    handleAmountPaidChange("append", 5,)
                  }}
                >
                  $5
                </button>
                <button
                  style={styles.button}
                  onClick={() => {
                    handleAmountPaidChange("append", 10,)
                  }}
                >
                  $10
                </button>
                <button
                  style={styles.button}
                  onClick={() => {
                    handleAmountPaidChange("append", 20,)
                  }}
                >
                  $20
                </button>
                <button
                  style={styles.button}
                  onClick={() => {
                    handleAmountPaidChange("append", 50,)
                  }}
                >
                  $50
                </button>
                <button
                  style={styles.button}
                  onClick={() => {
                    handleAmountPaidChange("append", 100,)
                  }}
                >
                  $100
                </button>

              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column", width: width * 0.4, justifyContent: "center" }}>
          <NumPad.Number
            onChange={(value: string) => {
              const numberInput = Number(value)
              if (numberInput < 0) {
                setErrorMsg("Paid amount must be larger than 0!")
                setIsAlertModalVisible(true)
              } else if (numberInput > 99999) {
                setErrorMsg("Paid amount must be Less than $99999!")
                setAmountPaid(0)
                setIsAlertModalVisible(true)

              } else {
                handleAmountPaidChange("input", numberInput)
              }
            }}
            value={amountPaid}
            decimal={2}
            negative={false}
            inline={true}
            sync={true}
          // theme={myTheme}
          // position={"center"}
          />
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", alignItems: "center", marginTop: 20 }}>
            <button
              onClick={() => {
                handleAmountPaidChange("reset", 0)
              }}
              style={{ ...styles.button, border: "none", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal", fontSize: "18px", width: "190px", height: "50px" }}
            >
              Clear
            </button>
            <button
              style={{ ...styles.button, border: "none", backgroundColor: "#32b373", color: "#fff", fontWeight: "normal", fontSize: "18px", width: "190px", height: "50px" }}
              onClick={() => {
                handleProceedPayment()
              }}
            >
              Proceed
            </button>
            {/* <Button 
              type="primary"
              onClick={() => {
                // handleAmountPaidChange("reset", 0)
              }}
              style={{ ...styles.button, fontSize: 22, width: 200, height: 50, marginLeft: 20, display: "flex", justifyContent: "center", alignItems: "center", alignSelf: "center" }}
            >
              Confirm
            </Button> */}
          </div>
        </div>
      </div>
      <div style={{ marginTop: "20px", justifyContent: "flex-start", alignItems: "center", display: "flex", flexDirection: "row" }}>
        <button
          style={{ height: "50px", width: "300px", border: "none", borderRadius: "5px", backgroundColor: "#E63737", color: "#fff", fontWeight: "normal", fontSize: "18px" }}
          onClick={() => {
            setIsCheckoutPaymentModalVisible && setIsCheckoutPaymentModalVisible(true)
            setVisibility(false)
          }}
        >
          Cancel Payment
        </button>
      </div>
      <ProgressModal isVisible={isInProgress} description={"Creating Invoice"} />
      <AlertModal
        isVisible={isAlertModalVisible}
        description={errorMsg}
        buttonText={"OK"}
        onCancel={() => setIsAlertModalVisible(false)}
        onConfirm={() => setIsAlertModalVisible(false)}
      />
    </Modal>
  )
}

export default CashModal

const styles: StyleSheet = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  button: {
    fontSize: 25,
    width: 80,
    height: 90,
    fontWeight: "bold",
    borderRadius: 5,
    border: "1px solid #2C6EF1",
    backgroundColor: "#E3EDFF"
  }
}